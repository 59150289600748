
/* image */
.csLogo {
    width: 85%;
    max-width: 500px;
    padding: 10px;
    padding-top: 30px;
}


.AgendaForm-topdiv {
    /* background-color: #f7f6f2; */
    background-color: rgba(12,85,123,0.81);
    /* color: #4b6587; */
    /* color: rgba(17,42,82,0.75); */
    color: white;
    min-height: 101vh;
    display: flex;
    align-items: flex-start;
    /* padding-top: 10%; */
    justify-content: center;
    /* font-size: calc(10px + 2vmin); */
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
  }

.PTFont {
font-family: "PT Serif",serif

}

.PrimaryButton {
    width: 200px;
    height: 70px;
    border-radius: 20px;
    font-size: 27px;
    font-weight: bold;
    margin: 15px;
}

.SecondaryButton {
    width: 150px;
    height: 50px;
    border-radius: 20px;
    font-size: 20px;
    font-weight: bold;
    margin: 13px;
}

.notesContainer {
    margin-top: 1rem;
    margin-left: 2rem;
    /* width: fit-content; */
}

.notesArea {
    height: 300px;
    width: 85%; 
}

.ErrorText {
    color: rgba(12,85,123,0.81);
}

.BlueButton {
    color: rgba(12,85,123,0.81);
    border-color: rgba(12,85,123,0.81);

}
.BlueButton:hover {
    background-color: rgba(12,85,123,0.81);
    border-color: rgba(12,85,123,0.81);

}



.bottomLine{
    background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.2), rgba(0,0,0,0));
    height: 1px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.Createh1 {
    font-size: 4rem;
    font-weight: bold;
    padding: 1.5rem;
    padding-left: 2rem;
}

.Createh2 {
    font-size: 3rem;
    font-weight: bold;
    text-align: left;
    /* text-decoration: underline; */
}

.CreateFormDiv {
    align-content: flex-start;
    text-align: left;
    font-size: medium;
}

.createFeedback {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    color: #a8a6a6;
}

.inputFeedback {
    font-size: 1rem;
    font-weight: 400;
    /* text-align: center; */
    color: #c96d6d;
}

.Headline {
    font-size: 3rem;
    font-weight: bold;
    padding: 1.5rem;
    padding-left: 2rem;
    align-self: flex-start;
}

.subHeadline {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: left;
    padding-left: 2rem;
    padding-right: 5rem;
}

.title {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    padding-left: 2rem;
    padding-right: 5rem;
}

.formDiv {
    text-align: left;
    font-size: medium;
    margin-left: 2rem;
    width: fit-content;
}
