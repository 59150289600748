
.bottomLine{
    background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.2), rgba(0,0,0,0));
    height: 1px;
    margin-bottom: 2rem;
}

.Createh1 {
    font-size: 4rem;
    font-weight: bold;
    padding: 1rem;
    padding-left: 2rem;
}

.Createh2 {
    font-size: 3rem;
    font-weight: bold;
    text-align: left;
    /* text-decoration: underline; */
}

.CreateFormDiv {
    align-content: flex-start;
    text-align: left;
    font-size: medium;
}

.createFeedback {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    color: #a8a6a6;
}

.inputFeedback {
    font-size: 1rem;
    font-weight: 400;
    /* text-align: center; */
    color: #c96d6d;
}

