
.CompletedList_Container {
    padding: 7%;
    padding-top: 2%;
  }
  
  .bottomLine{
      background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.2), rgba(0,0,0,0));
      height: 1px
  }
  
  .Createh1 {
      font-size: 4rem;
      font-weight: bold;
      padding: 1rem;
      padding-left: 2rem;
  }
  
  .Createh2 {
      font-size: 3rem;
      font-weight: bold;
      text-align: left;
      /* text-decoration: underline; */
  }
  
  .CreateFormDiv {
      align-content: flex-start;
      text-align: left;
      font-size: medium;
  }
  
  .createFeedback {
      font-size: 1.5rem;
      font-weight: 400;
      text-align: center;
      color: #a8a6a6;
  }
  
  .scheduleCardh4 {
      font-size: 1.5rem;
      font-weight: 600;
      text-align: left;
      margin-left: 15px;
  }
  
  .scheduleCardh5 {
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      /* color: #a8a6a6; */
  }

  .scheduleCardDetailsContainer {
    padding: 1rem;
  }

  .notesContainer {
    background-color: 'red';
    border-radius: 10px;
  }
  
  .scheduleCardDetailsh4 {
    font-size: 1.5rem;
      font-weight: 600;
  }

  .scheduleCardDetailsh5 {
    font-size: 1rem;
    font-weight: 400;
    padding-left: 1rem;
  }

  .SearchFormClass {
    width: 50%;
    margin-left: 25%;
  }