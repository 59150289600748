/* 
div[data-title]:hover:after {
    content: attr(data-title);
    padding: 4px 8px;
    color: #333;
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 20px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: 0px 0px 4px #222;
    -webkit-box-shadow: 0px 0px 4px #222;
    box-shadow: 0px 0px 4px #222;
  } */

  [title]:hover:after{
    content: attr(title);
    background: #4b6587;
    color: #f7f6f2;
    font-size: small;
    padding: 3px;
    border-radius:3px;
    border:1px solid;
    position: absolute;
    z-index: 1000;
  }