
/** fadeInRight **/

:root {
    --duration: 0.51s;
    --delay: 0.1s;
}

@-webkit-keyframes fadeInRight {
    from {
        opacity:0;
        -webkit-transform: translatex(10px);
        -moz-transform: translatex(10px);
        -o-transform: translatex(10px);
        transform: translatex(10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeInRight {
    from {
        opacity:0;
        -webkit-transform: translatex(10px);
        -moz-transform: translatex(10px);
        -o-transform: translatex(10px);
        transform: translatex(10px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeInRight {
    from {
        opacity:0;
        -webkit-transform: translatex(100px);
        -moz-transform: translatex(100px);
        -o-transform: translatex(100px);
        transform: translatex(100px);
    }
    to {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
.in-right {
    -webkit-animation-name: fadeInRight;
    -moz-animation-name: fadeInRight;
    -o-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: var(--duration);
    -moz-animation-duration: var(--duration);
    -o-animation-duration: var(--duration);
    animation-duration: var(--duration);
    -webkit-animation-delay: var(--delay);
    -moz-animation-delay: var(--delay);
    -o-animation-duration:var(--duration);
    animation-delay: var(--delay);
}






/** fadeOutLeft **/

/* :root {
    --duration: 0.51s;
    --delay: 0.1s;
} */

@-webkit-keyframes fadeOutLeft {
    to {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    from {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@-moz-keyframes fadeOutLeft {
    to {
        opacity:0;
        -webkit-transform: translatex(-10px);
        -moz-transform: translatex(-10px);
        -o-transform: translatex(-10px);
        transform: translatex(-10px);
    }
    from {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
@keyframes fadeOutLeft {
    to {
        opacity:0;
        -webkit-transform: translatex(-100px);
        -moz-transform: translatex(-100px);
        -o-transform: translatex(-100px);
        transform: translatex(-100px);
    }
    from {
        opacity:1;
        -webkit-transform: translatex(0);
        -moz-transform: translatex(0);
        -o-transform: translatex(0);
        transform: translatex(0);
    }
}
.out-left {
    -webkit-animation-name: fadeOutLeft;
    -moz-animation-name: fadeOutLeft;
    -o-animation-name: fadeOutLeft;
    animation-name: fadeOutLeft;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: var(--duration);
    -moz-animation-duration: var(--duration);
    -o-animation-duration: var(--duration);
    animation-duration: var(--duration);
    -webkit-animation-delay: var(--delay);
    -moz-animation-delay: var(--delay);
    -o-animation-duration:var(--duration);
    animation-delay: var(--delay);
}